<template>
  <v-container id="test" fluid tag="section">
    <v-row>
      <v-col cols="auto">
        <v-card-actions class="pa-0">
          <v-spacer />
          <v-btn color="success" min-width="100" @click="loadData(true)">
            Reset
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="itemsPerPage"
      :sort-by="[]"
      :sort-desc="[]"
      :server-items-length="totalItems"
      :options.sync="options"
      dense
      multi-sort
      :loading="loading"
      :footer-props="{
        itemsPerPageOptions: [20, 100],
      }"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-text-field
          ref="searchKeyword"
          v-model="searchItems.ipAddresses"
          class="mx-4"
          :label="`${$t('ip-address')} ${$t('search')}`"
          validate-on-blur
          :rules="ruleIpAddresses"
          @keyup.enter="searchIpAddresses($event)"
        ></v-text-field>
      </template>
      <template v-slot:item.userId="{ item }">
        <a :href="getLegacyAdminUrl(`users/${item.userId}`)" target="_blank">{{
          item.userId
        }}</a>
      </template>
    </v-data-table>
    <v-snackbar v-model="snackbar" top centered>
      {{ notificationMessage }}
    </v-snackbar>
  </v-container>
</template>

<script>
import {
  searchItemsToFilters,
  getQueryString,
  filtersToSearchItems,
} from "@/utils/paginator";

const REGEX_IP = /^(,?\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})+$/;

export default {
  data: () => ({
    loading: true,
    options: {},
    itemsPerPage: 10,
    totalItems: 0,
    items: [],

    searchItems: {
      ipAddresses: null,
    },
    filters: "",

    snackbar: false,
    notificationMessage: "",

    rules: {
      ipAddresses: (v) =>
        (v !== undefined && v !== null && REGEX_IP.test(v)) ||
        "invalid ip address list (separate by comma)",
    },
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$t("user-id"),
          value: "userId",
          filterable: false,
          sortable: false,
        },
        {
          text: this.$t("device"),
          value: "userAgent",
          filterable: false,
          sortable: false,
        },
        {
          text: this.$t("ip-address"),
          value: "ip",
          filterable: false,
          sortable: false,
        },
      ];
    },
    ruleIpAddresses() {
      return [this.rules.ipAddresses];
    },
    getLegacyAdminUrl() {
      return (path) => {
        const host = this.getLegacyAdminHost();
        return `//${host}/${path}`;
      };
    },
  },

  watch: {
    options: {
      async handler() {
        // console.log("in handler,", { options: this.options });
        await this.loadData();
      },
      deep: true,
    },
  },
  methods: {
    clearFilters() {
      this.options = { page: 1, itemsPerPage: 20 };
      this.filters = null;
    },

    async loadData(isReset = false) {
      try {
        if (isReset) {
          this.clearFilters();
        }
        this.loading = true;
        // console.log("options:", this.options);
        const { page, itemsPerPage: limits } = this.options;
        const queryString = getQueryString({
          limits,
          page,
          filters: this.filters,
        });

        const requestUrl = `/api/search-ip${queryString}`;
        const res = await this.authGet(requestUrl);
        this.items = res.data.data;
        this.totalItems = Number(res.data.totalItems);
        this.filters = res.data.filters;
        this.searchItems = filtersToSearchItems(this.filters);
        console.log("in loadData,", {
          msal: this.$msal,
          page,
          limits,
          filters: this.filters,
          queryString,
          requestUrl,
          totalItems: this.totalItems,
          searchItems: this.searchItems,
        });
        this.loading = false;
      } catch (e) {
        this.processError(e);
      }
    },

    processError(e) {
      const { response } = e;
      if (response) {
        this.showNotification(response.data);
      } else {
        console.error(e);
        this.showNotification(e);
      }
    },

    showNotification(msg) {
      this.notificationMessage = msg;
      this.snackbar = true;
    },

    async searchIpAddresses() {
      // for validate-on-blur
      // const validateOnBlur = this.$refs.searchKeyword.validateOnBlur;
      // console.log("result validation", validateOnBlur);
      console.log(
        "searchIpAddresses,",
        JSON.stringify({ searchItems: this.searchItems })
      );
      this.filters = searchItemsToFilters(this.searchItems);
      await this.loadData();
    },
  },
};
</script>
